<template>
  <div class="ApplytraininginStitution">
    <div class="left_main">
      <p>课程内容:</p>
      <div v-for="item in classContent" :key="item.value">
        {{ item.name }}
      </div>
    </div>
    <span class="line_main"></span>
    <div class="reight_main">
      <el-table :data="tableData" highlight-current-row @cell-click="cellClick">
        <el-table-column prop="training_type" label="类型">
          <template slot-scope="scope">
            <el-input
              size="small"
              ref="tableInput"
              v-model="scope.row.training_type"
              @blur="removeClass"
              @change="
                handleEditInput('training_type', scope.$index, scope.row)
              "
            ></el-input>
            <span>{{ scope.row.training_type }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="factory_name" label="绑定云工厂">
          <template #default="scope">
            <el-select
              filterable
              v-model="scope.row.factory_name"
              @change="handleEditSelect(scope.$index, scope.row, 1)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in fact_selectListData"
                :key="item.key"
                :label="item.fact_name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="name" label="公司名称"></el-table-column>
        <el-table-column prop="phone" label="电话"></el-table-column>
        <el-table-column prop="course" label="选择课程"></el-table-column>
        <el-table-column prop="info" label="备注">
          <template slot-scope="scope">
            <el-input
              size="small"
              ref="tableInput"
              v-model="scope.row.info"
              @blur="removeClass"
              @change="handleEditInput('info', scope.$index, scope.row)"
            ></el-input>
            <span>{{ scope.row.info }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="group_name" label="分配培训机构">
          <template #default="scope">
            <el-select
              filterable
              v-model="scope.row.group_name"
              @change="handleEditSelect(scope.$index, scope.row, 2)"
              placeholder="请选择"
            >
              <el-option
                v-for="item in group_selectListData"
                :key="item.key"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </template>
        </el-table-column>
        <!-- <el-table-column
          v-for="(item, index) in tableColumn"
          :key="index"
          :prop="item.prop"
          :label="item.label"
        >
          <template slot-scope="scope">
            <el-input
              v-if="item.editinput"
              size="small"
              ref="tableInput"
              v-model="scope.row[item.prop]"
              @blur="removeClass"
              @change="handleEditInput(item.prop, scope.$index, scope.row)"
            ></el-input>
            <span>{{ scope.row[item.prop] }}</span>
          </template>
        </el-table-column> -->
      </el-table>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

import upload from '../../utils/uploadOss'

export default {
  data() {
    return {
      selectvalue: '',
      // 左侧文案
      classContent: [
        {
          value: 1,
          name: '1、短视频创作与运营:3000元/人',
        },
        {
          value: 2,
          name: '2、直播销售技能:6000元/人',
        },
        {
          value: 3,
          name: '3、门店成交率提升:3000元/人',
        },
        {
          value: 4,
          name: '4、工厂生产管理:6000元/人',
        },
        {
          value: 5,
          name: '5、干部梯队建设:3000元/单位',
        },
        {
          value: 6,
          name: '6、测量设计拆单:3000元/人',
        },
        {
          value: 7,
          name: '7、安装交付技能:3000元/人',
        },
      ],
      // 表格数据
      tableData: [],
      //  table lable
      tableColumn: [
        {
          label: '类型',
          prop: 'training_type',
          editinput: true,
        },
        {
          label: '绑定云工厂',
          prop: 'factory_name',
          editselect: true,
        },
        {
          label: '公司名称',
          prop: 'name',
        },
        {
          label: '电话',
          prop: 'phone',
          align: 'center',
        },
        { label: '选择课程', prop: 'course' },
        { label: '备注', prop: 'info', editinput: true },
        {
          label: '分配培训机构',
          prop: 'group_name',
          editselect: true,
        },
      ],
      // 下拉的数据
      fact_selectListData: [],
      group_selectListData: [],
    }
  },
  watch: {},
  computed: {},
  created() {
    this.getList()
    this.getStationGruopList()
    this.getFactroyListFun()
  },
  methods: {
    // table 选择下拉
    handleEditSelect(e, row, index) {
      let payload = {}
      if (index == 1) {
        payload = {
          id: row.id,
          training_type: row.training_type,
          factory_id: row.factory_name,
          info: row.info,
          group_id: row.group_id,
        }
      } else if (index == 2) {
        payload = {
          id: row.id,
          training_type: row.training_type,
          factory_id: row.factory_id,
          info: row.info,
          group_id: row.group_name,
        }
      }
      console.log('e', e)
      console.log('row', row)
      this.addInfoFun(payload)
    },
    // table 编辑框
    handleEditInput(name, e, row) {
      console.log('name', name)
      console.log('e', e)
      console.log('row', row)
      let payload = {
        id: row.id,
        training_type: row.training_type,
        factory_id: row.factory_id,
        info: row.info,
        group_id: row.group_id,
      }
      this.addInfoFun(payload)
    },
    // 点击 单元格 添加class
    cellClick(row, column, cell, event) {
      for (
        let i = 0;
        i < document.getElementsByClassName('current-cell').length;
        i++
      ) {
        document
          .getElementsByClassName('current-cell')
          [i].classList.remove('current-cell')
      }
      cell.classList.add('current-cell')
    },
    // 移除class
    removeClass() {
      document
        .getElementsByClassName('current-cell')[0]
        .classList.remove('current-cell')
    },
    // 获取列表数据
    getList() {
      this.$request.getApplytraininginStitution({}, (res) => {
        console.log('获取da列表数据', res)
        if (res.status == 1) {
          this.tableData = res.data
        }
      })
    },
    // 获取培训机构下拉列表数据
    getStationGruopList() {
      this.$request.getGroupInfo({}, (res) => {
        console.log('获取培训机构列表', res)
        if (res.status == 1) {
          this.group_selectListData = res.data
        }
      })
    },
    // 获取云工厂列表
    getFactroyListFun() {
      this.$request.getFactroyList({ limit: 1000 }, (res) => {
        console.log('云工厂列表获取成功', res)
        if (res.status == 1) {
          this.fact_selectListData = res.data.res
        }
      })
    },
    // 填写信息
    addInfoFun(payload) {
      this.$request.getAddInfo(payload, (res) => {
        console.log('添加成功', res)
      })
    },
  },
}
</script>

<style lang="less" scoped>
.ApplytraininginStitution {
  padding: 0 32px;
  background: white;
  display: flex;
  height: 100%;
  .left_main {
    padding: 10px;
    > p {
      font-size: 18px;
      color: black;
      font-weight: 700;
    }
    > div {
      margin-top: 5px;
      font-size: 16px;
      color: black;
      font-weight: 700;
    }
  }
  .line_main {
    padding: 10px 0;
    height: 100%;
    width: 1px;
    background: rgba(128, 128, 128, 0.507);
  }
  .reight_main {
    width: 100%;
  }
}
.reight_main .el-input {
  display: none;
}
.current-cell .el-input {
  display: block;
}
.current-cell .el-input + span {
  display: none;
}
// .reight_main .el-select {
//   display: none;
// }
// .current-cell .el-select {
//   display: block;
// }
// .current-cell .el-select {
//   display: none;
// }
</style>
